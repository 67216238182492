<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 图文列表 -->
      <ServiceRichList :list='list1' v-ani.fade-up />
      <!-- 案例 -->
      <ServiceCase title="项目案例" :list='list2' />
      <!-- 案例 -->
      <ServiceCase title="图纸案例" :list='list3' />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc8_0.jpg'),
        //   title: '深化设计',
        //   intro: '高精度设计深度'
        // },
      ],
      info: {
        title: '深化设计',
        desc: '在业主或设计顾问提供的蓝图的基础上，结合各专业图纸及施工现场实际情况，对图纸进行细化、补充和完善。深化设计后的图纸满足业主或设计顾问的技术要求，符合相关地域的设计规范和施工规范，并通过审查，图形合一，能直接指导现场施工，确保最终效果更加美观合理。',
        list: [
          { id: 1, title: '填写担保<br>申请书' },
          { id: 2, title: '审查申请人<br>的申请内容' },
          { id: 3, title: '签订协议' },
          { id: 4, title: '银行为申请人<br>开立投标保函' },
        ]
      },
      list1: [
        {
          id: 1, icon: require('@/assets/images/svc8_01.png'),
          title: '成熟管理模式',
          desc: '认同初步设计图纸，签约合同'
        }, {
          id: 2, icon: require('@/assets/images/svc8_02.png'),
          title: '公开合理收费',
          desc: '通过测量，定制初步的设计方案'
        }, {
          id: 3, icon: require('@/assets/images/svc8_03.png'),
          title: '口碑发展信誉生存',
          desc: '通过测量，定制初步的设计方案'
        }
      ],
      list2: [
        {
          id: 1,
          img: require('@/assets/images/svc8_1.jpg'),
          tit: '新造镇广州国际创新城一期C居住',
        }, {
          id: 2,
          img: require('@/assets/images/svc8_2.jpg'),
          tit: '新造镇广州国际创新城一期C居住',
        }, {
          id: 3,
          img: require('@/assets/images/svc8_3.jpg'),
          tit: '新造镇广州国际创新城一期C居住',
        }, {
          id: 4,
          img: require('@/assets/images/svc8_4.jpg'),
          tit: '新造镇广州国际创新城一期C居住',
        },
      ],
      list3: [
        {
          id: 1,
          img: require('@/assets/images/svc8_5.jpg'),
          tit: '图纸案例',
        }, {
          id: 2,
          img: require('@/assets/images/svc8_6.jpg'),
          tit: '图纸案例',
        }, {
          id: 3,
          img: require('@/assets/images/svc8_7.jpg'),
          tit: '图纸案例',
        }, {
          id: 4,
          img: require('@/assets/images/svc8_8.jpg'),
          tit: '图纸案例',
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>